<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>
html,body{margin:0;box-sizing: border-box;}
.el-header,.el-footer{padding:0;}
.el-main{background: #fff;padding:0;margin:20px;box-shadow: -1px 2px 13px 2px rgb(0 0 0 / 10%);border-radius: 2px;}
.contmain{padding:20px;box-sizing: border-box;}
.addtops{padding-bottom: 30px;}
.addtops .a1{font-size: 18px;font-weight: bold;}
.addpages{margin-top: 35px;}
</style>
