<template>
  <!-- <el-footer class="cflex" style="color: #666; font-size: 14px;border-top:1px solid #eee;">九六网提供技术支持</el-footer
    > -->
  <div
    class="cflex"
    style="
      color: #666;
      font-size: 14px;
      padding: 0 20px;
      height: 100%;
      box-sizing: border-box;
    "
  >
    <a
      href="https://www.996w.com/"
      target="_blank"
      style="color: #666; font-size: 14px;text-decoration:none;"
      >九六网提供技术支持</a
    >
  </div>
</template>

<script>
export default {
  name: "",
};
</script>

<style lang='scss' scoped>
</style>