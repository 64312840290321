<template>
  <el-aside width="150px" style="height: 100%">
    <el-menu
      :default-active="$route.name"
      background-color="#121a3b"
      text-color="#fff"
      style="height: 100%;border-right:0;"
    >
      <!-- <el-menu-item index="home" @click="gorouter('/home')">
        <i class="el-icon-delete"></i>
        <span slot="title">
          基础设置
        </span>
      </el-menu-item> -->
      <!-- <img src="../assets/img/pcimg2.png" class="img1" style="width:150px"/> -->
      <div style="margin-top:80px"></div>
      <el-menu-item index="guanli" @click="gorouter('/guanli')" v-show="isvip==1">
        <i class="el-icon-user"></i>
        <span slot="title">管理员管理</span>
      </el-menu-item>
      <el-menu-item index="dingdan" @click="gorouter('/dingdan')">
        <i class="el-icon-goods"></i>
        <span slot="title">订单管理</span>
      </el-menu-item>
      <el-menu-item index="huiyuan" @click="gorouter('/huiyuan')">
        <i class="el-icon-s-custom"></i>
        <span slot="title">会员管理</span>
      </el-menu-item>
    </el-menu>
  </el-aside>
</template>

<script>
export default {
  name:'comleft',
  data() {
    return {
      isvip:'',
    }
  },
  
  mounted() {
    if (!JSON.parse(sessionStorage.getItem("logininfo"))) {
      this.$router.push("/login");
    } else {
      this.isvip = JSON.parse(sessionStorage.getItem("logininfo")).isvip;
      // console.log(this.isvip,2)
    }
  },
  methods:{
    gorouter(data){
      this.$router.push(data);
    }
  },
}
</script>

<style lang='scss' scoped>
  .el-menu{
    a{color:#fff;text-decoration: none;}
    .is-active{
      a{color:#409EFF}
    }
  }
  .img1{width: 200px;padding: 10px;box-sizing: border-box;}
</style>