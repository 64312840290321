import Vue from "vue"
import Router from "vue-router"
import notfount from '@/components/404'
import Home from './views/Home.vue' //首页文件
import Login from './views/Login.vue' //登录页面
import Guanli from './views/Guanli.vue' //管理员列表
import Dingdan from './views/Dingdan.vue' //订单列表
import Huiyuan from './views/Huiyuan.vue' //会员


Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

export default new Router({
  // mode:'history',
  routes:[
    {
      path:'',
      redirect:'/home'  //重定向
    },
    {
      path:'/home',
      name:'home',
      component:Home
    },
    {
      path:'/login',
      name:'login',
      component:Login
    },
    {
      path:'/guanli',
      name:'guanli',
      component:Guanli
    },
    {
      path:'/dingdan',
      name:'dingdan',
      component:Dingdan
    },
    {
      path:'/huiyuan',
      name:'huiyuan',
      component:Huiyuan
    },
    {
      path: '*',
      name: 'notfount',
      component: notfount
    }
  ]
})