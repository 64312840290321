<template>
  <div class="ccontainer">
    <el-container style="height: 100%">
      <Comleft></Comleft>
      <el-container>
        <el-header><Comhead></Comhead></el-header>
        <el-main>
          <div class="contmain">
            <div class="addtops fsb">
              <div class="a1">订单列表</div>

              <div class="adddaoru">
                <el-button type="primary" size="small" @click="addClick" class="ad1"
                  >新增</el-button
                >
                

                <el-button size="small" type="primary" @click="dialogVisible1 = true" style="margin-right:20px">导入</el-button>
                <el-dialog
                  title="提示"
                  :visible.sync="dialogVisible1"
                  width="30%">
                  <span>导入订单会覆盖原本存在的订单，请核对后再导入!</span>
                  <span slot="footer" class="dialog-footer" style="display:flex;justify-content:flex-end;">
                    <el-button @click="dialogVisible1 = false" style="margin-right:10px">取 消</el-button>
                    <el-upload action="" :http-request="uploadImgList1" class="ad1">
                      <el-button type="primary" @click="dialogVisible1 = false">确 定</el-button>
                    </el-upload>
                  </span>
                </el-dialog>


                <el-button type="primary" size="small" class="ad1" @click="down"
                  >下载导入模板文件</el-button
                >
                <el-button type="primary" size="small" class="ad1" @click="exportDemo">导出</el-button>
              </div>
            </div>
            <el-form ref="form" label-width="100px" class="searchBox">
              <div class="searchBoxItem">
                <el-form-item label="型号">
                  <el-input v-model="title" placeholder="型号"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                  <!-- <el-input v-model="status" placeholder="状态"></el-input> -->
                  <el-select v-model="status" placeholder="请选择">
                  <el-option value="" label="全部">全部</el-option>
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                </el-form-item>
                <el-form-item label="下单日期">
                  <!-- <el-col :span="11">
                    <el-date-picker type="date" placeholder="选择开始日期" v-model="starttime" style="width: 100%;" value-format="yyyy-MM-dd"></el-date-picker>
                  </el-col>
                  <el-col class="line" :span="2" style="text-align:center">-</el-col>
                  <el-col :span="11">
                    <el-date-picker type="date" placeholder="选择结束日期" v-model="endtime" style="width: 100%;" value-format="yyyy-MM-dd"></el-date-picker>
                  </el-col> -->
                  <el-date-picker
                    v-model="chooseTime"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd">
                  </el-date-picker>
                </el-form-item>
              </div>
              <div class="searchBoxItem">
                <el-form-item label="关键词">
                  <el-input v-model="words" placeholder="客户/业务员"></el-input>
                </el-form-item>
                <el-form-item>
                  <div class="SearchSubmit" @click="SearchSubmit()">搜索</div>
                </el-form-item>
              </div>
            </el-form>
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column prop="id" label="id" width="60">
              </el-table-column>
              
              <el-table-column prop="realname" label="客户" width="65">
              </el-table-column>
              <el-table-column prop="salesman" label="业务员" width="65">
              </el-table-column>
              <el-table-column prop="sn" label="订单号" width="80">
              </el-table-column>
              <!-- <el-table-column prop="productionNo" label="生产编号" min-width="40">
                <template slot-scope="scope">
                  <p v-for="(item , index) in scope.row.productlist" :key="index">{{item.productionNo}}</p>
                </template>
              </el-table-column> -->
              <el-table-column prop="title" label="名称/型号" width="100">
                <template slot-scope="scope">
                  <p v-for="(item , index) in scope.row.productlist" :key="index">{{item.title}}</p>
                </template>
              </el-table-column>
              <el-table-column prop="spec" label="规格" width="80">
                <template slot-scope="scope">
                  <p v-for="(item , index) in scope.row.productlist" :key="index">{{item.spec}}</p>
                </template>
              </el-table-column>
              <el-table-column prop="color" label="颜色" width="60">
                <template slot-scope="scope">
                  <p v-for="(item , index) in scope.row.productlist" :key="index">{{item.color}}</p>
                </template>
              </el-table-column>
              <el-table-column prop="unit" label="单位" width="50">
                <template slot-scope="scope">
                  <p v-for="(item , index) in scope.row.productlist" :key="index">{{item.unit}}</p>
                </template>
              </el-table-column>
              <el-table-column prop="num" label="数量" width="50">
                <template slot-scope="scope">
                  <p v-for="(item , index) in scope.row.productlist" :key="index">{{item.num}}</p>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="price" label="单价" width="60">
                <template slot-scope="scope">
                  <p v-for="(item , index) in scope.row.productlist" :key="index">{{item.price}}</p>
                </template>
              </el-table-column>
              <el-table-column prop="total" label="金额" width="70" v-if="isvip==1">
                <template slot-scope="scope">
                  <p v-for="(item , index) in scope.row.productlist" :key="index">{{item.total}}</p>
                </template>
              </el-table-column> -->
              <el-table-column prop="remark" label="备注" min-width="20">
                <template slot-scope="scope">
                  <p v-for="(item , index) in scope.row.productlist" :key="index">{{item.remark}}</p>
                </template>
              </el-table-column>
              <el-table-column label="状态" width="80">
                <template slot-scope="scope">
                  <div v-if="scope.row.status == 1">下单成功</div>
                  <div v-if="scope.row.status == 2">生产中</div>
                  <div v-if="scope.row.status == 3">待发货</div>
                  <div v-if="scope.row.status == 4">已发货</div>
                  <div v-if="scope.row.status == -1">取消</div>
                </template>
              </el-table-column>
              <el-table-column label="下单日期" width="100"  prop="orderTime">
              </el-table-column>
              <el-table-column label="最后操作员" width="100"  prop="lastname">
              </el-table-column>
              <el-table-column label="最后操作日期" width="120"  prop="lastTime">
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="80">
                <template slot-scope="scope">
                  <el-button
                    @click="handleClick(scope.row)"
                    type="text"
                    size="small"
                    >编辑</el-button
                  >
                  <el-popconfirm
                    title="这是一段内容确定删除吗？"
                    @confirm="del(scope.row.id)"
                    v-if="isvip==1"
                  >
                    <el-button slot="reference" type="text" size="small" style="padding-left:5px;">删除</el-button>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
            <!-- <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="limit"
              @current-change="handleCurrentChange"
              class="addpages"
            >
            </el-pagination> -->
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-size="10"
              layout="total, sizes, prev, pager, next"
              :total="total">
            </el-pagination>
          </div>
        </el-main>
        <el-footer><Comfoot></Comfoot></el-footer>
      </el-container>
    </el-container>

    <el-dialog
      :title="diatitle"
      :visible.sync="dialogVisible"
      width="75%"
      :before-close="handleClose"
    >
    <!-- 编辑订单 -->
      <!-- <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        label-position="left"
        v-show="diatitle !=='新增'"
      >
        <el-form-item label="客户" prop="realname">
          {{ruleForm.realname}}
        </el-form-item>
        <el-form-item label="订单状态" prop="status">
          <el-select v-model="ruleForm.status" placeholder="请选择">
            <el-option
              v-for="item in options"
              v-show="item.value >= ruleForm.status || item.value == -1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        
        <el-form-item label="业务员" prop="salesman">
          <el-select v-model="ruleForm.salesmanid" filterable clearable placeholder="请选择">
            <el-option
              v-for="item in salesmanOptions"
              :key="item.id"
              :label="item.realname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单号" prop="sn">
          <el-input v-model="ruleForm.sn"></el-input>
        </el-form-item>
        <el-form-item label="物流单据" prop="danju">
          <el-upload
            class="upload-demo"
            action=""
            :file-list="ruleForm.danju"
            :http-request="uploadImgList"
            list-type="picture"
            :before-remove="beforeRemove"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form> -->

      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        label-position="left"
        v-show="diatitle !=='新增'"
      >
        <div style="display:flex;">
          <el-form-item label="客户名字" prop="mid">
            <el-select v-model="ruleForm.mid" filterable clearable placeholder="请选择">
              <el-option
                v-for="item in customerOptions"
                :key="item.id"
                :label="item.realname"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="业务员" prop="salesman" style="margin-left:30px">
            <el-select v-model="ruleForm.salesmanid" filterable clearable placeholder="请选择">
              <el-option
                v-for="item in salesmanOptions"
                :key="item.id"
                :label="item.realname"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        
        <el-form-item label="发货单号" prop="sn">
          <el-input v-model="ruleForm.sn"></el-input>
        </el-form-item>
        <div style="display:flex;">
          <el-form-item label="状态" prop="status">
            <el-select v-model="ruleForm.status" placeholder="请选择">
              <el-option
                v-for="item in options"
                v-show="item.value >= ruleForm.status || item.value == -1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="下单时间" prop="orderTime" style="margin-left:30px">
            <el-date-picker
              v-model="ruleForm.orderTime"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </div>
        <el-form-item label="物流单据" prop="danju">
          <el-upload
            class="upload-demo"
            action=""
            :file-list="ruleForm.danju"
            :http-request="uploadImgList"
            list-type="picture"
            :before-remove="beforeRemove"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-table
          class="addTable"
          :data="ruleForm.tableData"
          stripe
          style="width: 100%">
          <el-table-column
            fixed
            prop="title"
            label="名称/型号"
            >
            <template slot-scope="scope">
              <el-input v-model="scope.row.title"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="spec"
            label="规格"
            width="120">
            <template slot-scope="scope">
              <el-input v-model="scope.row.spec"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="color"
            label="颜色"
            width="80">
             <template slot-scope="scope">
              <el-input v-model="scope.row.color"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="unit"
            label="单位"
            width="80">
            <template slot-scope="scope">
              <el-input v-model="scope.row.unit"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="num"
            label="数量"
            width="80">
            <template slot-scope="scope">
              <el-input v-model="scope.row.num"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="price"
            label="单价"
            width="80">
            <template slot-scope="scope">
              <el-input v-model="scope.row.price"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="total"
            label="金额"
            width="100">
            <template slot-scope="scope">
              <el-input v-model="scope.row.total"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            >
            <template slot-scope="scope">
              <el-input v-model="scope.row.remark"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="120">
            <template slot-scope="scope">
              <el-button
                v-show="(ruleForm.tableData.length !==1)"
                @click.native.prevent="deleteRow(scope.$index, tableData)"
                type="text"
                size="small">
                移除
              </el-button>
              <el-button
                v-show="(ruleForm.tableData.length - 1 == scope.$index)"
                @click.native.prevent="addRow(scope.$index, tableData)"
                type="text"
                size="small">
                新增
              </el-button>
              
            </template>
          </el-table-column>
        </el-table>
      </el-form>




    <!-- 新增订单 -->
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        label-position="left"
         v-show="diatitle =='新增'"
      >
        <div style="display:flex;">
          <el-form-item label="客户名字" prop="mid">
            <el-select v-model="ruleForm.mid" filterable clearable placeholder="请选择">
              <el-option
                v-for="item in customerOptions"
                :key="item.id"
                :label="item.realname"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="业务员" prop="salesman" style="margin-left:30px">
            <el-select v-model="ruleForm.salesmanid" filterable clearable placeholder="请选择">
              <el-option
                v-for="item in salesmanOptions"
                :key="item.id"
                :label="item.realname"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        
        <el-form-item label="发货单号" prop="sn">
          <el-input v-model="ruleForm.sn"></el-input>
        </el-form-item>
        <div style="display:flex;">
          <el-form-item label="状态" prop="status">
            <el-select v-model="ruleForm.status" placeholder="请选择">
              <el-option
                v-for="item in options"
                v-show="item.value >= ruleForm.status || item.value == -1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="下单时间" prop="orderTime" style="margin-left:30px">
            <el-date-picker
              v-model="ruleForm.orderTime"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </div>

        <el-table
          class="addTable"
          :data="ruleForm.tableData"
          stripe
          style="width: 100%">
          <el-table-column
            fixed
            prop="title"
            label="名称/型号"
            >
            <template slot-scope="scope">
              <el-input v-model="scope.row.title"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="spec"
            label="规格"
            width="120">
            <template slot-scope="scope">
              <el-input v-model="scope.row.spec"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="color"
            label="颜色"
            width="80">
             <template slot-scope="scope">
              <el-input v-model="scope.row.color"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="unit"
            label="单位"
            width="80">
            <template slot-scope="scope">
              <el-input v-model="scope.row.unit"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="num"
            label="数量"
            width="80">
            <template slot-scope="scope">
              <el-input v-model="scope.row.num"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="price"
            label="单价"
            width="80">
            <template slot-scope="scope">
              <el-input v-model="scope.row.price"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="total"
            label="金额"
            width="100">
            <template slot-scope="scope">
              <el-input v-model="scope.row.total"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            >
            <template slot-scope="scope">
              <el-input v-model="scope.row.remark"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="120">
            <template slot-scope="scope">
              <el-button
                v-show="(ruleForm.tableData.length !==1)"
                @click.native.prevent="deleteRow(scope.$index, tableData)"
                type="text"
                size="small">
                移除
              </el-button>
              <el-button
                v-show="(ruleForm.tableData.length - 1 == scope.$index)"
                @click.native.prevent="addRow(scope.$index, tableData)"
                type="text"
                size="small">
                新增
              </el-button>
              
            </template>
          </el-table-column>
        </el-table>




      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button  @click="addRow1()" style="margin :-20px 0 auto auto;text-align:right;" type="warning" v-if="ruleForm.tableData.length==0">新增一条产品记录</el-button>
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Comleft from "../components/comleft.vue";
import Comhead from "../components/comhead.vue";
import Comfoot from "../components/comfoot.vue";
export default {
  name: "Guanli",
  components: {
    Comleft,
    Comhead,
    Comfoot,
  },
  data() {
    return {
      diatitle: "新增",
      dialogVisible: false,
      dialogVisible1:false,
      options: [
        {
          value: 1,
          label: "下单成功",
        },
        {
          value: 2,
          label: "生产中",
        },
        {
          value: 3,
          label: "待发货",
        },
        {
          value: 4,
          label: "已发货",
        },
        {
          value: -1,
          label: "取消",
        },
      ],
      salesmanOptions:[],
      customerOptions:[],
      ruleForm: {
        id: "",
        mid: "",
        title: "",
        unit: "",
        salesmanid:"",
        color: "",
        total: "",
        sn: "",
        remark: "",
        spec: "",
        status: "",
        num: "",
        price: "",
        customerid:"",
        orderTime:"",
        realname:'',
        danju: [
          // {
          //   name: "food.jpeg",
          //   url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
          // },
        ],

        tableData: [{
          title: '',
          spec: '',
          color: '',
          unit: '',
          num: '',
          price: 0,
          total:0,
          remark:''
        }]
        
      },
      rules: {
        title: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      imglist: [],
      tableData: [],
      token: "",
      page: 1,
      total: null,
      limit: 10,
      // isedit: false,
      words:'',
      title:'',
      status:'',
      chooseTime:"",
      starttime:'',
      endtime:'',
      isvip:''
    };
  },
  mounted() {
    this.token = JSON.parse(sessionStorage.getItem("logininfo")).token;
    this.isvip = JSON.parse(sessionStorage.getItem("logininfo")).isvip;
    this.getdatas();
  },
  methods: {
    // 新增数据时表格里添加多条数据
    addRow(index){
      const that = this
      const thidIndex = that.ruleForm.tableData[index]
      if(thidIndex.title == ''){
        alert("请输入名称/型号")
      }else if(thidIndex.spec == ''){
        alert("请输入规格")
      }else if(thidIndex.color == ''){
        alert("请输入颜色")
      }else if(thidIndex.unit == ''){
        alert("请输入单位")
      }else if(thidIndex.num == ''){
        alert("请输入数量")
      }else{
        const add = {
          title: '',
          spec: '',
          color: '',
          unit: '',
          num: '',
          price: 0,
          total:0,
          remark:''
        }
        that.ruleForm.tableData = that.ruleForm.tableData.concat(add)
        // console.log(that.ruleForm.tableData)
      }
    },


    addRow1(){
      const that = this
      const add = {
          title: '',
          spec: '',
          color: '',
          unit: '',
          num: '',
          price: 0,
          total:0,
          remark:''
        }
        that.ruleForm.tableData = that.ruleForm.tableData.concat(add)
    },
    // 新增数据时表格里删除某条数据
    deleteRow(index){
      const that = this
      that.ruleForm.tableData.splice(index, 1)
    },
    down() {
      var a = document.createElement("a"); // 创建一个<a></a>标签
      a.href =
        "https://shunafei.admin.996w.cn/%E8%88%92%E7%BA%B3%E8%8F%B2%E5%AF%BC%E5%85%A5%E8%AE%A2%E5%8D%95%E6%A0%BC%E5%BC%8F.xlsx";
      a.download = "https://shunafei.admin.996w.cn/舒纳菲导入订单格式.xlsx"; // 设置下载文件文件名
      a.style.display = "none"; // 隐藏a标签
      document.body.appendChild(a); // 将a标签追加到文档对象中
      a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
      a.remove(); // 一次性的，用完就删除a标签
    },
    // 导出
    exportDemo(){
      this.$axios
        .post("/api/Admin/Order/exportOrder", {
          words: '',
          token: this.token,
          page: this.page,
          limit: this.limit,
          title:'',
          status:'',
          starttime:'',
          endtime:'',
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data.filepath)
            var a = document.createElement("a"); // 创建一个<a></a>标签
            a.href = res.data.data.filepath // 设置下载文件文件名
            a.style.display = "none"; // 隐藏a标签
            document.body.appendChild(a); // 将a标签追加到文档对象中
            a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
            a.remove(); // 一次性的，用完就删除a标签
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    addClick() {
      this.dialogVisible = true;
      this.diatitle = "新增";
      this.$axios
        .post("/api/Admin/Member/getAll", {
          token: this.token,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.salesmanOptions = res.data.data
          } else {
            this.$message.error(res.data.message);
          }
        });
      this.$axios
        .post("/api/Admin/Member/getCustomerAll", {
          token: this.token,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.customerOptions = res.data.data
          } else {
            this.$message.error(res.data.message);
          }
        });
        this.ruleForm.id = "";
        this.ruleForm.mid = "";
        this.ruleForm.title = "";
        this.ruleForm.unit = "";
        this.ruleForm.salesmanid = "";
        this.ruleForm.color = "";
        this.ruleForm.total = "";
        this.ruleForm.sn = "";
        this.ruleForm.remark = "";
        this.ruleForm.spec = "";
        this.ruleForm.status = "";
        this.ruleForm.num = "";
        this.ruleForm.price = "";
        this.ruleForm.realname = "";
        this.ruleForm.tableData = [{
          title: '',
          spec: '',
          color: '',
          unit: '',
          num: '',
          price: 0,
          total:0,
          remark:''
        }];
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getdatas();
    },
    handleSizeChange(val) {
      this.limit = val;
      this.getdatas();
    },
    del(id) {
      this.$axios
        .post("/api/Admin/Order/del", {
          token: this.token,
          id,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message.success(res.data.message);
            this.getdatas();
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    getdatas() {
      this.$axios
        .post("/api/Admin/Order/list", {
          words: '',
          token: this.token,
          page: this.page,
          limit: this.limit,
          title:'',
          status:'',
          starttime:'',
          endtime:'',

        })
        .then((res) => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.data;
            this.total = res.data.data.total;
          } else if (res.data.code == 1001) {
            sessionStorage.clear();
            this.$router.push("/login");
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    handleClose() {
      this.dialogVisible = false;
      this.$refs["ruleForm"].resetFields();
      this.imglist = [];
      this.ruleForm.id = "";
      this.ruleForm.mid = "";
    },
    handleClick(data) {
      this.dialogVisible = true;
      this.diatitle = "编辑";
      this.$axios
        .post("/api/Admin/Order/get", {
          token: this.token,
          id: data.id,
        })
        .then((res) => {
          if (res.data.code == 0) {
            console.log(res.data.data,889)
            this.ruleForm.id = res.data.data.id;
            this.ruleForm.mid = res.data.data.mid;
            this.ruleForm.title = res.data.data.title;
            this.ruleForm.unit = res.data.data.unit;
            this.ruleForm.salesmanid = res.data.data.salesmanid;
            this.ruleForm.orderTime = res.data.data.orderTime;
            this.ruleForm.color = res.data.data.color;
            this.ruleForm.total = res.data.data.total;
            this.ruleForm.sn = res.data.data.sn;
            this.ruleForm.remark = res.data.data.remark;
            this.ruleForm.spec = res.data.data.spec;
            this.ruleForm.status = res.data.data.status;
            this.ruleForm.num = res.data.data.num;
            this.ruleForm.price = res.data.data.price;
            this.ruleForm.realname = res.data.data.realname;
            this.ruleForm.tableData = res.data.data.productlist;
            for(let i in res.data.data.imglist){
              this.ruleForm.danju.push({
                uid:res.data.data.imglist[i],
                name: "addimgs" + i,
                url: "http://shunafei.996w.cn" + res.data.data.imglist[i],
              });
            }
            // res.data.data.imglist.forEach((item, index) => {
            //   console.log(item);
            //   this.ruleForm.danju.push({
            //     name: "addimgs" + index,
            //     url: "http://shunafei.996w.cn" + item,
            //   });
            // });
            // console.log(this.ruleForm.danju);
            this.imglist = res.data.data.imglist;
          } else {
            this.$message.error(res.data.message);
          }
          // console.log(res.data.data);
        });
      this.$axios
        .post("/api/Admin/Member/getAll", {
          token: this.token,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.salesmanOptions = res.data.data
          } else {
            this.$message.error(res.data.message);
          }
        });
      this.$axios
        .post("/api/Admin/Member/getCustomerAll", {
          token: this.token,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.customerOptions = res.data.data
          } else {
            this.$message.error(res.data.message);
          }
        });




      this.$axios
        .post("/api/Admin/Member/getAll", {
          token: this.token,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.salesmanOptions = res.data.data
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    submitForm(formName) {
      if(this.diatitle=='新增'){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$axios
              .post("/api/Admin/Order/add", {
                token: this.token,
                mid: this.ruleForm.mid,
                salesmanid: this.ruleForm.salesmanid,
                sn: this.ruleForm.sn,
                status: this.ruleForm.status,
                orderTime:this.ruleForm.orderTime,
                datalist:this.ruleForm.tableData,
                // title: this.ruleForm.title,
                // spec: this.ruleForm.spec,
                // color: this.ruleForm.color,
                // unit: this.ruleForm.unit,
                // num: this.ruleForm.num,
                // price: this.ruleForm.price,
                // total: this.ruleForm.total,
                // remark: this.ruleForm.remark,
              })
              .then((res) => {
                if (res.data.code == 0) {
                  this.$message.success(res.data.message);
                  this.handleClose();
                  this.getdatas();
                  this.ruleForm.salesmanid = ''
                  this.ruleForm.tableData = [{
                        title: '',
                        spec: '',
                        color: '',
                        unit: '',
                        num: '',
                        price: 0,
                        total:0,
                        remark:''
                      }]
                } else {
                  this.$message.error(res.data.message);
                }
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }else{
        this.$axios
          .post("/api/Admin/Order/edit", {
            token: this.token,
            id: this.ruleForm.id,
            mid: this.ruleForm.mid,
            title: this.ruleForm.title,
            unit: this.ruleForm.unit,
            salesmanid: this.ruleForm.salesmanid,
            color: this.ruleForm.color,
            total: this.ruleForm.total,
            sn: this.ruleForm.sn,
            remark: this.ruleForm.remark,
            spec: this.ruleForm.spec,
            status: this.ruleForm.status,
            num: this.ruleForm.num,
            price: this.ruleForm.price,
            imglist: this.imglist,
            orderTime: this.ruleForm.orderTime,
            realname: this.ruleForm.realname,
            productlist: this.ruleForm.tableData,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.$message.success(res.data.message);
              this.handleClose();
              this.getdatas();
            } else {
              this.$message.error(res.data.message);
            }
          });
      }
    },
    beforeRemove(file) {
      // console.log(file.name);
      this.ruleForm.danju.forEach((item, index) => {
        if (item.name == file.name) {
          this.ruleForm.danju.splice(index, 1);
          // this.imglist.splice(index, 1);
          return;
        }
      });
    },
    uploadImgList1(params) {
      const file = params.file,
        form = new FormData();
      form.append("file", file);
      form.append(
        "token",
        JSON.parse(sessionStorage.getItem("logininfo")).token
      );
      this.$axios.post("/api/Admin/Order/ImportOrder", form).then((res) => {
        if (res.data.code == 0) {
          this.$message.success(res.data.message);
          this.getdatas();
        } else if (res.data.code == 1001) {
          sessionStorage.clear();
          this.$router.push("/login");
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    uploadImgList(params) {
      const file = params.file,
        form = new FormData();
      form.append("file", file);
      form.append(
        "token",
        JSON.parse(sessionStorage.getItem("logininfo")).token
      );
      this.$axios.post("/api/Admin/Setting/uploadImg", form).then((res) => {
        this.ruleForm.danju.push({
          uid:res.data,
          url: "http://shunafei.996w.cn" + res.data,
          name: file.name,
        });
        // this.imglist.push(res.data);
      });
    },

    SearchSubmit() {
      if(this.chooseTime !== ''){
        this.starttime = this.chooseTime[0]
        this.endtime = this.chooseTime[1]
      }
      
      // console.log(this.status)
      this.$axios
        .post("/api/Admin/Order/list", {
          words: this.words,
          token: this.token,
          page: this.page,
          limit: this.limit,
          title:this.title,
          status:this.status,
          starttime: this.starttime,
          endtime: this.endtime,
        })
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.code == 0) {
            this.tableData = res.data.data.data;
            this.total = res.data.data.total;
          } else if (res.data.code == 1001) {
            sessionStorage.clear();
            this.$router.push("/login");
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ccontainer {
  width: 100vw;
  height: 100vh;
}
.el-menu {
  border-right: solid 1px #fff;
}
::v-deep .el-dialog {
  margin-top: 10vh !important;
}
.demo-ruleForm {
  // height: 60vh;
  height: max-content;
  max-width: 100%;
  @extend .scrollbar;
  padding-right: 10px;
  box-sizing: border-box;
}
.adddaoru {
  display: flex;
  .ad1 {
    margin-right: 8px;
  }
  ::v-deep .el-upload-list {
    display: none;
  }
}
.searchBox{
  display: flex;
  flex-direction: column;
  .searchBoxItem{
    display: flex;
  }
  }
.SearchSubmit{
  padding:0 20px;
  background:#66b1ff;
  color:#fff;
  border-radius:6px;
  box-sizing:border-box;
  cursor:pointer
}
.el-table{margin-bottom: 30px;}

</style>