<template>
  <div class="container">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      class="ruleForm"
      label-position="top"
    >
      <el-form-item>
        <img src="../assets/img/pcimg3.png" class="img1" />
      </el-form-item>
      <el-form-item label="用户名" prop="name">
        <el-input v-model="ruleForm.name" size="large" />
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          v-model="ruleForm.password"
          type="password"
          show-password
          size="large"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          color="#1F65D7"
          size="large"
          class="nohover clicks"
          @click="submitForm('ruleFormRef')"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
    <div class="foots">
      <a href="https://www.996w.com/" target="_blank">九六网提供技术支持</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      ruleForm: {
        name: "",
        password: "",
      },
      rules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .post("/api/Admin/Login/login", {
              username: this.ruleForm.name,
              password: this.ruleForm.password,
            })
            .then((res) => {
              if (res.data.code == 0) {
                this.$message.success(res.data.message);
                this.$router.push("/dingdan");
                sessionStorage.setItem('logininfo',JSON.stringify(res.data.data))
              }else{
                this.$message.error(res.data.message);
              }
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-image: url("../assets/img/login_bg.png");
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @extend .cflex;
  flex-direction: column;
  .ruleForm {
    background: #fff;
    width: 490px;
    box-sizing: border-box;
    padding: 80px 60px;
    .img1 {
      width: 196px;
      // height: 39px;
      display: block;
      margin: 0 auto 30px;
    }
    .clicks {
      display: block;
      width: 100%;
      font-size: 16px;
    }
  }
  .foots {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    a {
      color: #666;
      font-size: 14px;
    }
  }
}
</style>