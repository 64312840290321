<template>
  <div class="ccontainer">
    <el-container style="height: 100%">
      <Comleft></Comleft>
      <el-container>
        <el-header><Comhead></Comhead></el-header>
        <el-main>
          <div class="contmain">
            <div class="addtops fsb">
              <div class="a1">会员列表</div>
            </div>
            <el-form ref="form" label-width="100px" class="searchBox">
              <div class="searchBoxItem">
                <el-form-item label="关键词">
                  <el-input v-model="words" placeholder="关键词"></el-input>
                </el-form-item>
                <el-form-item>
                  <div class="SearchSubmit" @click="SearchSubmit()">搜索</div>
                </el-form-item>
              </div>
            </el-form>
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column prop="realname" label="名称" min-width="100">
              </el-table-column>
              <el-table-column prop="phone" label="电话" min-width="100"  v-if="isvip==1">
              </el-table-column>
              <el-table-column prop="address" label="地址" min-width="50">
              </el-table-column>
              <el-table-column prop="exp" label="网点" min-width="50">
              </el-table-column>
              <el-table-column prop="type" label="联系类型" min-width="50">
                <template slot-scope="scope">
                  <p v-if="scope.row.type==1">手机</p>
                  <p v-else>固话</p>
                </template>
              </el-table-column>
              <el-table-column prop="phone" label="网点联系方式" min-width="50">
              </el-table-column>
              <el-table-column prop="salesman" label="业务员" min-width="50">
              </el-table-column>
              <el-table-column prop="issalesman" label="是否业务员" min-width="50">
                <template slot-scope="scope">
                  <p v-if="scope.row.issalesman==1">是</p>
                  <p v-else>否</p>
                </template>
              </el-table-column>

              <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button
                    @click="handleClick(scope.row)"
                    type="text"
                    size="small"
                    >编辑</el-button
                  >
                  <el-popconfirm
                    title="这是一段内容确定删除吗？"
                    @confirm="del(scope.row.id)"
                    v-if="isvip==1"
                  >
                    <el-button slot="reference" type="text" size="small" style="padding-left:5px;">删除</el-button>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="limit"
              @current-change="handleCurrentChange"
              class="addpages"
            >
            </el-pagination>
          </div>
        </el-main>
        <el-footer><Comfoot></Comfoot></el-footer>
      </el-container>
    </el-container>

    <el-dialog
      :title="diatitle"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="dianhua"  v-if="isvip==1">
          <el-input v-model="ruleForm.dianhua"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="dizhi">
          <el-input v-model="ruleForm.dizhi"></el-input>
        </el-form-item>
        <el-form-item label="网点" prop="wuliu">
          <el-input v-model="ruleForm.wuliu"></el-input>
        </el-form-item>
        <el-form-item label="联系类型" prop="type">
          <el-select v-model="ruleForm.type" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="网点联系方式" prop="wuliuphone">
          <el-input v-model="ruleForm.wuliuphone"></el-input>
        </el-form-item>
        <el-form-item label="业务员" prop="wuliusalesman">
          <el-input v-model="ruleForm.wuliusalesman"></el-input>
        </el-form-item>
        <el-form-item label="是否业务员" prop="issalesman">
          <template>
            <el-radio v-model="ruleForm.issalesman" label="0">否</el-radio>
            <el-radio v-model="ruleForm.issalesman" label="1">是</el-radio>
          </template>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Comleft from "../components/comleft.vue";
import Comhead from "../components/comhead.vue";
import Comfoot from "../components/comfoot.vue";
export default {
  name: "Guanli",
  components: {
    Comleft,
    Comhead,
    Comfoot,
  },
  data() {
    return {
      diatitle: "新增",
      dialogVisible: false,
      options: [
        {
          value: "手机",
          label: "手机",
        },
        {
          value: "固话",
          label: "固话",
        },
      ],
      ruleForm: {
        id: "",
        name: "",
        dianhua: "",
        dizhi: "",
        wuliu: "",
        wuliuphone: "",
        wuliusalesman:"",
        type: "",
        issalesman:"0",
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      tableData: [],
      token: "",
      words:'',
      page: 1,
      total: null,
      limit: 8,
      isedit: false,
      isvip:'',
    };
  },
  mounted() {
    this.token = JSON.parse(sessionStorage.getItem("logininfo")).token;
    this.isvip = JSON.parse(sessionStorage.getItem("logininfo")).isvip;
    this.getdatas();
  },
  methods: {
    del(id) {
      this.$axios
        .post("/api/Admin/Member/del", {
          token: this.token,
          id,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message.success(res.data.message);
            this.getdatas();
          } else {
            this.$message.success(res.data.error);
          }
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getdatas();
    },
    getdatas() {
      this.$axios
        .post("/api/Admin/Member/list", {
          words: "",
          token: this.token,
          page: this.page,
          limit: this.limit,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.data;
            this.total = res.data.data.total;
          } else if (res.data.code == 1001) {
            sessionStorage.clear();
            this.$router.push("/login");
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    addClick() {
      this.dialogVisible = true;
      this.diatitle = "新增";
    },
    handleClose() {
      this.dialogVisible = false;
      this.$refs["ruleForm"].resetFields();
      this.ruleForm.id = "";
    },
    handleClick(data) {
      this.dialogVisible = true;
      this.diatitle = "编辑";
      this.$axios
        .post("/api/Admin/Member/get", {
          token: this.token,
          id: data.id,
        })
        .then((res) => {
          
          if (res.data.code == 0) {
            this.ruleForm.id = res.data.data.id;
            this.ruleForm.name = res.data.data.realname;
            this.ruleForm.dianhua = res.data.data.mobile;
            this.ruleForm.dizhi = res.data.data.address;
            this.ruleForm.wuliu = res.data.data.exp;
            this.ruleForm.wuliuphone = res.data.data.phone;
            this.ruleForm.wuliusalesman = res.data.data.salesman;
            this.ruleForm.type = res.data.data.type;
            this.ruleForm.issalesman = res.data.data.issalesman;
          } else if (res.data.code == 1001) {
            sessionStorage.clear();
            this.$router.push("/login");
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .post("/api/Admin/Member/edit", {
              token: this.token,
              id: this.ruleForm.id,
              realname: this.ruleForm.name,
              mobile: this.ruleForm.dianhua,
              address: this.ruleForm.dizhi,
              exp: this.ruleForm.wuliu,
              type: this.ruleForm.type,
              phone: this.ruleForm.wuliuphone,
              salesman: this.ruleForm.wuliusalesman,
              issalesman: this.ruleForm.issalesman,
            })
            .then((res) => {
              if (res.data.code == 0) {
                this.$message.success(res.data.message);
                this.handleClose();
                this.getdatas();
              } else {
                this.$message.error(res.data.message);
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    SearchSubmit() {
      this.$axios
        .post("/api/Admin/Member/list", {
          words: this.words,
          token: this.token,
          page: this.page,
          limit: this.limit,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.data;
            this.total = res.data.data.total;
          } else if (res.data.code == 1001) {
            sessionStorage.clear();
            this.$router.push("/login");
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ccontainer {
  width: 100vw;
  height: 100vh;
}
.el-menu {
  border-right: solid 1px #fff;
}
.searchBox{
  display: flex;
  flex-direction: column;
  .searchBoxItem{
    display: flex;
  }
  }
.SearchSubmit{
  padding:0 20px;
  background:#66b1ff;
  color:#fff;
  border-radius:6px;
  box-sizing:border-box;
  cursor:pointer;
  margin-left: -80px;
  text-align: center;
}
</style>