<template>
  <div class="ccontainer">
    <el-container style="height: 100%">
      <Comleft></Comleft>
      <el-container>
        <el-header><Comhead></Comhead></el-header>
        <el-main>
          <div class="contmain">
            <div class="addtops fsb">
              <div class="a1">物流网点设置</div>
              <el-button type="primary" size="small" @click="addClick"
                >新增</el-button
              >
            </div>
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column prop="title" label="网点名称" min-width="120">
              </el-table-column>
              <!-- <el-table-column prop="type" label="联系类型" min-width="80">
              </el-table-column> -->
              <!-- <el-table-column prop="mobile" label="联系方式" min-width="80">
              </el-table-column> -->
              <el-table-column prop="addTime" label="创建时间" min-width="80">
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button
                    @click="handleClick(scope.row)"
                    type="text"
                    size="small"
                    >编辑</el-button
                  >
                  <el-button type="text" size="small" @click="del(scope.row.id)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="limit"
              @current-change="handleCurrentChange"
              class="addpages"
            >
            </el-pagination>
          </div>
        </el-main>
        <el-footer><Comfoot></Comfoot></el-footer>
      </el-container>
    </el-container>

    <el-dialog
      :title="diatitle"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        label-position="top"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="联系类型" prop="type">
          <el-select v-model="ruleForm.type" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="联系方式" prop="mobile">
          <el-input v-model="ruleForm.mobile"></el-input>
        </el-form-item> -->
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Comleft from "../components/comleft.vue";
import Comhead from "../components/comhead.vue";
import Comfoot from "../components/comfoot.vue";
export default {
  name: "Home",
  components: {
    Comleft,
    Comhead,
    Comfoot,
  },
  data() {
    return {
      diatitle: "新增",
      dialogVisible: false,
      ruleForm: {
        id: "",
        name: "",
        type: "",
        mobile: "",
      },
      options: [
        {
          value: "手机",
          label: "手机",
        },
        {
          value: "固话",
          label: "固话",
        },
      ],
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        type: [
          { required: true, message: "请选择联系类型", trigger: "change" },
        ],
        mobile: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
        ],
      },
      tableData: [],
      token: "",
      page: 1,
      total: null,
      limit: 8,
      isedit: false,
    };
  },
  mounted() {
    this.token = JSON.parse(sessionStorage.getItem("logininfo")).token;
    this.getdatas();
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val
      this.getdatas();
    },
    getdatas() {
      this.$axios
        .post("/api/Admin/Setting/getExpList", {
          words: "",
          token: this.token,
          page: this.page,
          limit: this.limit,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.data;
            this.total = res.data.data.total;
          }else if (res.data.code == 1001){
            sessionStorage.clear()
            this.$router.push("/login");
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    del(id) {
      this.$axios
        .post("/api/Admin/Setting/del", {
          token: this.token,
          id,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message.success(res.data.message);
            this.getdatas();
          } else {
            this.$message.success(res.data.error);
          }
        });
    },
    addClick() {
      this.dialogVisible = true;
      this.diatitle = "新增";
      this.isedit = false;
    },
    handleClose() {
      this.dialogVisible = false;
      this.$refs["ruleForm"].resetFields();
      this.ruleForm.id = "";
    },
    handleClick(data) {
      this.dialogVisible = true;
      this.diatitle = "编辑";
      this.isedit = true;

      this.$axios
        .post("/api/Admin/Setting/get", {
          token: this.token,
          id: data.id,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.ruleForm.name = res.data.data.title;
            this.ruleForm.type = res.data.data.type;
            this.ruleForm.mobile = res.data.data.mobile;
            this.ruleForm.id = res.data.data.id;
          } else this.$message.error(res.data.message);
        });
      console.log(data);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.isedit) {
            // 编辑
            this.$axios
              .post("/api/Admin/Setting/save", {
                id: this.ruleForm.id,
                title: this.ruleForm.name,
                type: this.ruleForm.type,
                mobile: this.ruleForm.mobile,
                token: this.token,
              })
              .then((res) => {
                if (res.data.code == 0) {
                  this.$message.success(res.data.message);
                  this.handleClose();
                  this.getdatas();
                } else {
                  this.$message.error(res.data.message);
                }
              });
          } else {
            // 新增
            this.$axios
              .post("/api/Admin/Setting/save", {
                title: this.ruleForm.name,
                type: this.ruleForm.type,
                mobile: this.ruleForm.mobile,
                token: this.token,
              })
              .then((res) => {
                if (res.data.code == 0) {
                  this.$message.success(res.data.message);
                  this.handleClose();
                  this.getdatas();
                } else {
                  this.$message.error(res.data.message);
                }
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ccontainer {
  width: 100vw;
  height: 100vh;
}
.el-menu {
  border-right: solid 1px #fff;
}
</style>