<template>
  <el-header
    style="
      height:80px;
      background: #121a3b;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: end;
      padding: 0 20px;
      box-sizing: border-box;
    "
  >
    <img src="../assets/img/pcimg2.png" class="img1" style="width:210px;position:absolute;left:0;height:50px"/>
    <div style="font-size: 14px; margin-right: 10px">{{ name }}</div>
    <el-button type="primary" size="mini" @click="outlogin">退出登录</el-button>
  </el-header>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      name: "登录名",
    };
  },
  mounted() {
    if (!JSON.parse(sessionStorage.getItem("logininfo"))) {
      this.$router.push("/login");
    } else {
      this.name = JSON.parse(sessionStorage.getItem("logininfo")).username;
    }
  },
  methods: {
    outlogin() {
      this.$axios
        .post("/api/Admin/Login/logout", {
          token: JSON.parse(sessionStorage.getItem("logininfo")).token,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message.success(res.data.message);
            sessionStorage.clear()
            this.$router.push("/login");
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
  },
};
</script>

<style lang='scss' scoped>
</style>  